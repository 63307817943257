import LandingPageComponent from '../../../component/landing';
import React from 'react';

const IndexPage = () => {
  return (
    <LandingPageComponent
      isFinal={true}
      defaultUtmSource='fb'
      headerVariant={1}
    />
  );
};

export default IndexPage;
